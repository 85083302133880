import { BankActionTypes } from './constants';

const INIT_STATE = {
    addChequeReceipts: null,
    addBanks: null,
    updChequeReceipts: null,
    addChequePayments: null,
    updChequePayments: null,
    loading: false,
    modalLoading: false,
    error: null,
};

const Bank = (state = INIT_STATE, action) => {
    switch (action.type) {
        case BankActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BankActionTypes.Add_CheckReceipt:
                    return {
                        ...state,
                        addChequeReceipts: action.payload,
                    };
                case BankActionTypes.ADD_BANK:
                    return {
                        ...state,
                        addBanks: action.payload,
                        modalLoading:false
                    };
                case BankActionTypes.UPD_CheckReceipt:
                    return {
                        ...state,
                        updChequeReceipts: action.payload,
                    };
                case BankActionTypes.Add_CheckPayment:
                    return {
                        ...state,
                        addChequePayments: action.payload,
                    };
                case BankActionTypes.UPD_CheckPayment:
                    return {
                        ...state,
                        updChequePayments: action.payload,
                    };
                default:
                    return { ...state };
            }
        case BankActionTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                addChequeReceipts: null,
                addBanks: null,
                updChequeReceipts: null,
                addChequePayments: null,
                updChequePayments: null,
                modalLoading:true,
                loading: true,
            };
        case BankActionTypes.Add_CheckReceipt:
            return { ...state, loading: true, addChequeReceipts: null };
        case BankActionTypes.ADD_BANK:
            return { ...state, modalLoading: true, addBanks: null };
        case BankActionTypes.UPD_CheckReceipt:
            return { ...state, loading: true, updChequeReceipts: null };
        case BankActionTypes.Add_CheckPayment:
            return { ...state, loading: true, addChequePayments: null };
        case BankActionTypes.UPD_CheckPayment:
            return { ...state, loading: true, updChequePayments: null };
        case BankActionTypes.RESET:
            return {
                ...state,
                loading: false,
                addChequeReceipts: null,
                updChequeReceipts: null,
                addChequePayments: null,
                updChequePayments: null,
                modalLoading:false,
                addBanks: null,
            };
        default:
            return { ...state };
    }
};

export default Bank;
