import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {addCheque_Payments, addCheque_Receipts,managebank_API, updCheque_Payments, updCheque_Receipts} from '../../helpers';
import { BankResponseSuccess, BankResponseError, } from './actions';
// constants
import { BankActionTypes } from './constants';
type cashReceiptData = {
    payload: {id:string;entrydate:string;description:string;totalamount:string;voucherdetdata:[]};
    type: string;
};
type bankData = {
    payload: {name:string;};
    type: string;
};

function* add_chequeRec({ payload: {entrydate,description,totalamount,voucherdetdata} }: cashReceiptData): SagaIterator {
    try {
        const response = yield call(addCheque_Receipts, {entrydate,description,totalamount,voucherdetdata});
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.Add_CheckReceipt, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.Add_CheckReceipt, error));
    }
}
function* upd_chequeRec({ payload: {id,entrydate,description,totalamount,voucherdetdata} }: cashReceiptData): SagaIterator {
    try {
        const response = yield call(updCheque_Receipts, {id,entrydate,description,totalamount,voucherdetdata});
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.UPD_CheckReceipt, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.UPD_CheckReceipt, error));
    }
}
function* add_chequePayment({ payload: {entrydate,description,totalamount,voucherdetdata} }: cashReceiptData): SagaIterator {
    try {
        const response = yield call(addCheque_Payments, {entrydate,description,totalamount,voucherdetdata});
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.Add_CheckPayment, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.Add_CheckPayment, error));
    }
}
function* upd_chequePayment({ payload: {id,entrydate,description,totalamount,voucherdetdata} }: cashReceiptData): SagaIterator {
    try {
        const response = yield call(updCheque_Payments, {id,entrydate,description,totalamount,voucherdetdata});
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.UPD_CheckPayment, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.UPD_CheckPayment, error));
    }
}

function* carrier_Add({payload:{name}}: bankData):  SagaIterator{
    try{
        const response = yield call(managebank_API,{name})
        const item = response.data
        yield put(BankResponseSuccess(BankActionTypes.ADD_BANK,item))
       
    }catch (error: any) {
       yield put(BankResponseError(BankActionTypes.ADD_BANK, error)); 
       
    }
}

export function* watchAddRec(): any{   
   yield takeEvery(BankActionTypes.Add_CheckReceipt, add_chequeRec);
   yield takeEvery(BankActionTypes.UPD_CheckReceipt, upd_chequeRec);
   yield takeEvery(BankActionTypes.Add_CheckPayment, add_chequePayment);
   yield takeEvery(BankActionTypes.UPD_CheckPayment, upd_chequePayment);
   yield takeEvery(BankActionTypes.ADD_BANK, carrier_Add);
}

function* BankSaga() {
    yield all([fork(watchAddRec)]);
}

export default BankSaga;
