import { APICore } from './apiCore';
const api = new APICore();

function addCheque_Receipts(params) {
    const baseUrl = '/add-cheque-receipt';
    return api.create(`${baseUrl}`, params);
    
}
function managebank_API(params: {name :string}) {
  console.log('Carrier added---');
  console.log(params)
  const baseUrl = `/add-bank`;
  return api.create(`${baseUrl}`, params);
}
function updCheque_Receipts(params) {
    const baseUrl = `/update-cheque-receipt/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function addCheque_Payments(params) {
    const baseUrl = '/add-cheque-payment';
    return api.create(`${baseUrl}`, params);
    
}
function updCheque_Payments(params) {
    const baseUrl = `/update-cheque-payment/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}

export { addCheque_Receipts,updCheque_Receipts,
    addCheque_Payments,
    updCheque_Payments,managebank_API}