import {BankActionTypes} from './constants'


export const BankResponseSuccess = (actionType ,data)=>({
    type: BankActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType,data}
})
export const BankResponseError = (actionType ,error)=>({
    type: BankActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType,error}
})
export const updCheckReceipt = (id,entrydate,description,totalamount,voucherdetdata) => ({
    type: BankActionTypes.UPD_CheckReceipt,
    payload: {
    id,entrydate,description,totalamount,voucherdetdata
    },
});

export const manageBanks = (name: string) => ({
    type: BankActionTypes.ADD_BANK,
    payload: {name},
});

export const addCheckReceipt = (entrydate,description,totalamount,voucherdetdata) => ({
    type: BankActionTypes.Add_CheckReceipt,
    payload: {
    entrydate,description,totalamount,voucherdetdata
    },
});
export const updCheckPayment = (id,entrydate,description,totalamount,voucherdetdata) => ({
    type: BankActionTypes.UPD_CheckPayment,
    payload: {
    id,entrydate,description,totalamount,voucherdetdata
    },
});

export const addCheckPayment = (entrydate,description,totalamount,voucherdetdata) => ({
    type: BankActionTypes.Add_CheckPayment,
    payload: {
    entrydate,description,totalamount,voucherdetdata
    },
});

export const resetBank = () => ({
    type: BankActionTypes.RESET,
    payload: {},
});
